<template>
    <div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t("organizationalChart.company")
                }}</label>
                <select
                    v-model.trim.number="model.company_id"
                    class="form-select"
                    :class="{ 'is-invalid': errors.has('company_id') }"
                    ref="company_id"
                >
                    <option
                        :value="null"
                        disabled
                        selected
                        style="display: none;"
                        >{{ $t("select") }}</option
                    >
                    <option
                        v-for="(company, index) in companies"
                        :key="index"
                        :value="company.company_id"
                        >{{ company.company_name_en }}</option
                    >
                </select>
                <div class="invalid-feedback" v-if="errors.has('company_id')">
                    {{ errors.first("company_id") }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t("organizationalChart.description")
                }}</label>
                <input
                    v-model.trim="model.level_description"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('level_description') }"
                />
                <div
                    class="invalid-feedback"
                    v-if="errors.has('level_description')"
                >
                    {{ errors.first("level_description") }}
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh">
                <ts-button
                    @click.prevent="() => $emit('cancel')"
                    class="btn-gray"
                >
                    {{ $t("cancel") }}</ts-button
                >
                <ts-button
                    v-if="!isUpdate"
                    color="primary"
                    outline
                    :waiting="waiting_new"
                    :disabled="waiting_new || waiting"
                    @click.prevent="onSaveAddNew"
                >
                    {{ $t("saveAddNew") }}</ts-button
                >
                <ts-button
                    color="primary"
                    v-if="!isUpdate"
                    :waiting="waiting"
                    :disabled="waiting_new"
                    @click.prevent="onSave"
                >
                    {{ $t("save") }}</ts-button
                >
                <ts-button
                    v-if="isUpdate"
                    color="primary"
                    @click.prevent="onUpdate"
                    :waiting="waiting"
                >
                    {{ $t("update") }}</ts-button
                >
            </div>
        </div>
        <Spin size="large" fix v-if="loading"> </Spin>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions, mapState } from "vuex";
import { isEmpty } from "lodash";

export default {
    name: "organizational-chartForm",
    data() {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            model: {
                company_id: null,
                level_description: null
            }
        };
    },
    computed: {
        ...mapState("humanResource/organizationalChart", [
            "edit_data",
            "companies"
        ]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        }
    },
    methods: {
        ...mapActions("humanResource/organizationalChart", ["getCompany"]),
        async fetchResource() {
            this.loading = true;
            await this.getCompany();
            this.loading = false;
        },

        onSaveAddNew() {
            this.errors = new Errors();
            this.waiting_new = true;
            this.$store
                .dispatch("humanResource/organizationalChart/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting_new = false;
                });
        },
        onSave() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch("humanResource/organizationalChart/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        onUpdate() {
            this.waiting = true;
            this.$store
                .dispatch("humanResource/organizationalChart/update", {
                    id: this.edit_data.org_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        setEditData() {
            this.$nextTick(function() {
                this.$refs.company_id.focus();
            });
            this.fetchResource();
            if (!isEmpty(this.edit_data)) {
                this.model.company_id = this.edit_data.company_id;
                this.model.level_description = this.edit_data.level_description;
            }
        },
        clearInput() {
            this.errors = new Errors();
            this.model.company_id = null;
            this.model.level_description = null;
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "ORGANIZATIONAL CHART",
                desc: not.text
            });
        }
    }
};
</script>
